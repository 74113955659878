export default {
    main_manager: [
        {
            action: ["list"],
            subject: ["Emails"]
        }
    ],
    admin: [
        {
            action: ["list"],
            subject: ["Emails"]
        }
    ],
    manager: []
};
